import React from "react";
import s from "./About.module.css";
import photo from "../images/about-me.jpg";

const About = React.forwardRef(({ scrollToFooter }, ref) => {
  return (
    <div className={s.wrapper}>
      <section
        className={`${s.container} section`}
        ref={ref}
        data-test-id="about-me"
      >
        <h1 className="section__title">O mnie</h1>

        <p>
          Doświadczenie lektorskie budowałam podczas dwuletniej pracy w
          aplikacji Audiodelic. Czytałam artykuły „Tygodnika Powszechnego”,
          „Charakterów”, „Twojego Stylu” i wielu innych.
        </p>

        <p>
          Obecnie współpracuje ze studiem Red Noses Production House przy
          tworzeniu spotów reklamowych i promocyjnych.
          <br />
          <a className={s.link} href="https://rednoses.pl/" target="_blank">
            https://rednoses.pl/
          </a>
        </p>

        <p>
          Rozwijam się również teatralnie – współpracowałam z rzeszowskim
          teatrem Maska, krakowskim teatrem Graciarnia, a obecnie jestem aktorką
          krakowskiego teatru Powidok.
          <br />
          <a className={s.link} href="https://www.teatrpowidok.pl/" target="_blank">
            https://www.teatrpowidok.pl/
          </a>
        </p>

        <p>
          Interesuję się emisją głosu i przetwarzaniem mowy, również od strony
          technicznej. Studiuję Informatykę Społeczną na AGH w Krakowie, w
          ramach pracy naukowej zajmuje się zagadnieniem generowania mowy na
          podstawie tekstu. Kocham śpiewać! Współpracuję z agencją artystyczną
          Moc.Art, koncertowałam na wielu eventach w całej Polsce.
          <br />
          <a className={s.link} href="https://www.facebook.com/mocartagencja" target="_blank">
            https://www.facebook.com/mocartagencja
          </a>
        </p>

        <p>
          Chętnie podejmę współpracę i użyczę mojego głosu. <br />
          <a className={s.link} href="#footer" onClick={scrollToFooter}>
            {" "}
            Skontaktuj się.
          </a>
        </p>
      </section>

      <div className={s.photo}></div>
    </div>
  );
});

export default About;
