import React from "react";
import Testimonial from "./Testimonial";
import { testimonials } from "../../content/testimonials.yml";

const Testimonials = React.forwardRef((props, ref) => {
  return (
    <section className="section" id="testimonials" ref={ref}>
      <div className="content-col">
        <h1>Referencje</h1>
        <div>
          {testimonials.map((t) => (
            <Testimonial {...t} />
          ))}
        </div>
      </div>
    </section>
  );
});

export default Testimonials;
