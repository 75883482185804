import React from "react";
import SampleTile from "./SampleTile";
import s from "./Samples.module.css";
import { samples } from "../../content/samples.yml";

const groupByCategory = (arr = []) => {
  return arr.reduce((acc, curr) => {
    if (acc[curr.category]) {
      acc[curr.category].push(curr);
    } else {
      acc[curr.category] = [curr];
    }

    return acc;
  }, {});
};

const Category = ({ name, samples }) => (
  <div className={s.category}>
    <h2>{name}</h2>
    {samples.map((s) => (
      <SampleTile {...s} />
    ))}
  </div>
);

const Samples = React.forwardRef((props, ref) => {
  const groupedSamples = groupByCategory(samples);
  const categories = Object.keys(groupedSamples);

  return (
    <section className="section" ref={ref}>
      <div className="content-col">
        <h1>Próbki głosu</h1>

        <div className={s.container}>
          {categories.map((c) => (
            <Category name={c} samples={groupedSamples[c]} />
          ))}
        </div>
      </div>
    </section>
  );
});

export default Samples;
