import React from "react";
import s from "./SampleTile.module.css";

const SampleTile = ({ title, fileName }) => {
  return (
    <div>
      <p>{title}</p>

      <div className={`${s.player} js-sample-player`}>
        <audio>
          <source src={fileName} type="audio/mp3" />
        </audio>
      </div>
    </div>
  );
};

export default SampleTile;
