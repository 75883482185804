import React, { useEffect, useRef } from "react";
import GreenAudioPlayer from "green-audio-player";
import { window } from "browser-monads";
import { useSpring } from "react-spring";

import "sanitize.css";
import "green-audio-player/dist/css/green-audio-player.min.css";
import "./style.css";

import Navigation from "../components/Navigation";
import Cover from "../components/Cover";
import About from "../components/About";
import Samples from "../components/Samples";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";

export default function Home() {
  useEffect(() => {
    GreenAudioPlayer.init({
      selector: ".js-sample-player",
      stopOthersOnPlay: true,
    });
  }, []);

  const aboutRef = useRef();
  const samplesRef = useRef();
  const testimonialsRef = useRef();
  const footerRef = useRef();
  const topbarRef = useRef();

  let isStopped = false;
  const onWheel = () => {
    isStopped = true;
    window.removeEventListener("wheel", onWheel);
  };

  const [springProps, setY] = useSpring(() => ({
    y: window.pageYOffset,
    reset: true,
    onRest: () => {
      isStopped = false;
      window.removeEventListener("wheel", onWheel);
    },
  }));

  const handleScrollTo = (ref) => {
    window.addEventListener("wheel", onWheel);

    const y = ref.current.getBoundingClientRect().top;

    const topbarHeight = topbarRef.current.clientHeight;
    const topbarOffset = window.matchMedia("(min-width: 768px)").matches
      ? topbarHeight
      : 0;
    const scrollTo = window.pageYOffset + y - topbarOffset;

    setY({
      from: { y: window.pageYOffset },
      y: scrollTo,
      onFrame: (props) => {
        if (!isStopped) {
          window.scroll(0, props.y);
        }
      },
    });
  };

  const scrollToFooter = () => handleScrollTo(footerRef);

  return (
    <div>
      <Navigation
        handleScrollTo={handleScrollTo}
        aboutRef={aboutRef}
        samplesRef={samplesRef}
        testimonialsRef={testimonialsRef}
        topbarRef={topbarRef}
        footerRef={footerRef}
      />
      <Cover />
      <About ref={aboutRef} scrollToFooter={scrollToFooter} />
      <Samples ref={samplesRef} />
      <Testimonials ref={testimonialsRef} scrollToFooter={scrollToFooter} />
      <Footer ref={footerRef} />
    </div>
  );
}
