import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import wave from "../images/wave.png";
import s from "./Navigation.module.css";
import ContactInfo from "./ContactInfo";

const NavItem = ({ href, children, onClick, className }) => (
  <li className={`${s.item} ${className}`}>
    <a href={href} className={s.item} onClick={onClick}>
      {children}
    </a>
  </li>
);

const Navigation = ({
  handleScrollTo,
  aboutRef,
  samplesRef,
  testimonialsRef,
  topbarRef,
  footerRef,
}) => {
  const [isVisible, setVisible] = useState(false);
  const visibleClass = isVisible ? s.navVisible : s.navHidden;

  const navStyle = useSpring({ left: isVisible ? "0" : "100vw" });
  const showStyle = useSpring({ opacity: isVisible ? "0" : "1" });
  const hideStyle = useSpring({ opacity: isVisible ? "1" : "0" });

  const handleClick = (ref) => {
    setVisible(false);
    handleScrollTo(ref);
  };

  return (
    <div className={`${s.container} ${visibleClass}`}>
      <div className={s.topbar} ref={topbarRef}>
        <animated.a
          onClick={() => {
            setVisible(true);
          }}
          className={`lnr ${s.show}`}
          style={showStyle}
          data-test-id="nav-show"
        ></animated.a>
        <animated.a
          onClick={() => {
            setVisible(false);
          }}
          className={`lnr ${s.hide}`}
          style={hideStyle}
          data-test-id="nav-hide"
        ></animated.a>
      </div>

      <animated.nav
        className={`${s.navigation}`}
        style={navStyle}
        data-test-id="nav"
      >
        <ul>
          <img className={s.wave} src={wave} />
          <NavItem href="#about" onClick={() => handleClick(aboutRef)}>
            O mnie
          </NavItem>
          <NavItem href="#samples" onClick={() => handleClick(samplesRef)}>
            Próbki
          </NavItem>
          <NavItem
            href="#testimonials"
            onClick={() => handleClick(testimonialsRef)}
          >
            Referencje
          </NavItem>
          <NavItem
            href="#footer"
            onClick={() => handleClick(footerRef)}
            className={s.contactLink}
          >
            Kontakt
          </NavItem>
        </ul>

        <div className={s.contact}>
          <ContactInfo />
        </div>
      </animated.nav>
    </div>
  );
};

export default Navigation;
