import React from "react";
import s from "./Testimonial.module.css";

const Testimonial = ({ text, author }) => {
  return (
    <div className={s.testimonial}>
      <p>{text}</p>
      <p className={s.author}>~ {author}</p>
    </div>
  );
};

export default Testimonial;
