import React, { useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import s from "./Cover.module.css";
import logo from "../images/logo.png";

const Cover = () => {
  const ref = useRef();
  const [{ offset }, set] = useSpring(() => ({ offset: 0 }));

  const handleScroll = () => {
    const posY = ref.current.getBoundingClientRect().top;
    const offset = window.pageYOffset - posY;
    set({ offset });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const offsetPhoto = (o) => o > 0 && `translateY(${o * 0.2}px)`;
  const offsetLogo = (o) => o > 0 && `translateY(${o * 0.1}px)`;

  return (
    <animated.div
      className={s.container}
      ref={ref}
      style={{ transform: offset.interpolate(offsetPhoto) }}
    >
      <animated.div
        className={s.logoContainer}
        style={{ transform: offset.interpolate(offsetLogo) }}
      >
        <div className={s.logoContent}>
          <img src={logo} className={s.logo} />
        </div>
      </animated.div>

      <div className={`${s.cta} lnr lnr-chevron-down`}></div>
    </animated.div>
  );
};

export default Cover;
