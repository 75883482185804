import React from "react";
import s from "./Footer.module.css";
import ContactInfo from "./ContactInfo";

const Footer = React.forwardRef((props, ref) => {
  return (
    <footer className={`${s.container}`} ref={ref}>
      <div className={`${s.contact} content-col section`}>
        <p>Mogę coś dla Ciebie przeczytać? Napisz do mnie lub zadzwoń.</p>

        <ContactInfo />
      </div>

      <div className={s.copyright}>
        &copy; Maria Kubaszek | code
        <a href="https://codenroll.it" target="_blank">
          {" "}codenroll.it{" "}
        </a>
      </div>
    </footer>
  );
});

export default Footer;
