import React from "react";
import s from "./ContactInfo.module.css";

const ContactInfo = () => {
  return (
    <ul className={s.contact}>
      <li>
        <a className={s.contactItem} href="mailto:qubaszek@gmail.com">
          <span className={`lnr lnr-envelope ${s.icon}`}></span>
          maria.kubaszek@gmail.com
        </a>
      </li>
      <li>
        <a href="tel:+48515249737" className={s.contactItem}>
          <span className={`lnr lnr-phone-handset ${s.icon}`}></span>
          +48 515 249 737
        </a>
      </li>
    </ul>
  );
};

export default ContactInfo;
